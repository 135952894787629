.breadcrumb {
  a {
    text-decoration: none;
  }
  span {
    font-size: 12px; 
  }
  strong {
    font-size: 12px; 
  }
}
@include md {
  .breadcrumb {
    span {
      font-size: 14px; 
    }
    strong {
      font-size: 14px; 
    }
  }
}