/*
* COMPANY
*/

// 代表挨拶
.message-box {
    
}
.message-img {

    img {
        display:block;
        margin : auto;

        object-fit: cover;
        width: auto;
        height: 300px;
    }
}
.message-txt {
    padding-top: 20px;
    
    P {font-size: calc(1em - 2px);}
    
    small {
        font-size: calc(1em - 4px);
    }
    strong {
        font-size: calc(1em + 2px);
    }
}

@include md {
    .message-box {
        display: flex;
        justify-content: space-between;
    }
    .message-img { 
        flex-basis: 40%;
        order: 2;
        
        img {
            width: 100%;
            height: auto; 
        }
    }
    .message-txt {
        padding-top: 0px;
        flex-basis: calc(60% - 30px);
        
        order: 1;
    }
}

// 会社概要
.profile {
width: 100%;
}

.profile tr {
    border-top: 1px solid #ccc;
    padding: 0 20px;
    
    &:last-of-type {
        border-bottom: 1px solid #ccc;
    }
}

.profile {
    th,td {
        font-size: 12px;
    }
    th {
        padding: 20px 20px 20px 0;
    }
    td {
        padding: 20px 0;
    }
}
@include md {
    .profile {
        th,td {
            font-size: 14px;
        }
    }
}