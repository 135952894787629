/*
* CONTACT
*/

// コンタクト
.form-item {
    margin-top: 50px;
}
@include md {
    .form-item {
        margin-top: 60px;
    }
}
/* template01 */
/* template03 */
.form__wrap {
  width: 100%;
  max-width: 1000px;/*フォームの最大幅*/
  margin-right: auto;
  margin-left: auto;
}
.wpcf7 .template03 {
  color: $color;
  font-size: 14px;
}
.wpcf7 .template03 a {
  font-size: 14px;
  font-weight: 700;
  margin: 0;
  padding: 0;
  text-decoration: underline;
  color: $b_color;
  transition: opacity .25s;
}
.wpcf7 .template03 a:hover {
  opacity: .5;
  transition: opacity .25s;
}
.wpcf7 .template03 div.form__row {
  display: flex;
  align-items: center;
  margin: 0;
  border-top: dotted 1px #ccc;
  
  &:last-of-type {
    border-top: none;
  }

}
.wpcf7 .template03 div.form__row.row-privacy,
.wpcf7 .template03 div.form__row.row-submit {
  display: block;
  margin-top: 1em;
  text-align: center;
}
.wpcf7 .template03 div.form__row.row-privacy .form__body,
.wpcf7 .template03 div.form__row.row-submit .form__body {
  width: 100%;
}
.wpcf7 .template03 div.form__row.row-message {
  align-items: flex-start;
}
.wpcf7 p.form__label,
.wpcf7 p.form__body {
  margin: 0;
  box-sizing: border-box;
}
.wpcf7 .template03 p.form__label {
  width: 36%;
  padding: 1em;
}
.wpcf7 .template03 p.form__body {
  width: 64%;
  padding: 1em;
}
.wpcf7 .template03 p.form__label label {
  position: relative;
  padding-left: 44px;
  color: $b_color;
  font-size: 14px;
  font-weight: 700;
}
.wpcf7 .template03 p.form__label label::after {
  content: "任意";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 36px;
  margin: 0;
  padding: 0 5px;
  border-radius: 0;

  color: #999;
  font-size: 12px;
  text-align: center;
  box-sizing: border-box;
}
.wpcf7 .template03 p.form__label.is-required label {
  position: relative;
}
.wpcf7 .template03 p.form__label.is-required label::after {
  content: "必須";
  display: block;
  position: absolute;
  width: 36px;
  margin: 0;
  padding: 0 5px;

  color: #D43030;
  font-size: 12px;
  text-align: center;
  box-sizing: border-box;
}
/* テキストフィールド */
.wpcf7 .template03 input[type=text],
.wpcf7 .template03 input[type=tel],
.wpcf7 .template03 input[type=email],
.wpcf7 .template03 textarea {
  width: 100%;
  margin: 0;
  padding: .5em 1em;
  border: 1px solid #DDD;
  border-radius: 0;
  box-shadow: none;
  background-color: #fff;
  color: inherit;
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  
  &.wpcf7-not-valid {
    background: rgba(#D43030, 0.4);
  }
}
/* テキストフィールド placeholder */
.wpcf7 .template03 input[type=text]::placeholder,
.wpcf7 .template03 input[type=tel]::placeholder,
.wpcf7 .template03 input[type=email]::placeholder,
.wpcf7 .template03 input[type=url]::placeholder,
.wpcf7 .template03 input[type=date]::placeholder,
.wpcf7 .template03 input[type=number]::placeholder,
.wpcf7 .template03 textarea::placeholder {
  color: #ccc;
}
.wpcf7 .template03 input[type=text]:-ms-input-placeholder,
.wpcf7 .template03 input[type=tel]:-ms-input-placeholder,
.wpcf7 .template03 input[type=email]:-ms-input-placeholder,
.wpcf7 .template03 input[type=url]:-ms-input-placeholder,
.wpcf7 .template03 input[type=date]:-ms-input-placeholder,
.wpcf7 .template03 input[type=number]:-ms-input-placeholder,
.wpcf7 .template03 textarea:-ms-input-placeholder {
  color: #ccc;
}
.wpcf7 .template03 input[type=text]::-ms-input-placeholder,
.wpcf7 .template03 input[type=tel]::-ms-input-placeholder,
.wpcf7 .template03 input[type=email]::-ms-input-placeholder,
.wpcf7 .template03 input[type=url]::-ms-input-placeholder,
.wpcf7 .template03 input[type=date]::-ms-input-placeholder,
.wpcf7 .template03 input[type=number]::-ms-input-placeholder,
.wpcf7 .template03 textarea::-ms-input-placeholder {
  color: #ccc;
}
/* テキストフィールド フォーカス時 */
.wpcf7 .template03 input[type=text]:focus,
.wpcf7 .template03 input[type=tel]:focus,
.wpcf7 .template03 input[type=email]:focus,
.wpcf7 .template03 input[type=url]:focus,
.wpcf7 .template03 input[type=date]:focus,
.wpcf7 .template03 input[type=number]:focus,
.wpcf7 .template03 textarea:focus {
  outline: 0;
  box-shadow: 2px 3px 2px 1px rgba(205,205,205,.7);
}
/* チェックボックス */
.wpcf7 .template03 input[type=checkbox] {
  display: none;
}
.wpcf7 .template03 input[type=checkbox] + span {
  cursor: pointer;
  position: relative;
  margin: 0;
  padding: 0 1em 0 1.8em;
  font-size: inherit;
  box-sizing: border-box;
  transition: opacity .25s ease;
}
.wpcf7 .template03 input[type=checkbox] + span::before {
      font-size: 14px;
    font-weight: 700;
  content: "";
  opacity: 1;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 1.24em;
  height: 1.24em;
  border: 1px solid #333;
  border-radius: 0;
  background-color: #fff;
  box-sizing: border-box;
  transition: opacity .25s ease;
}
.wpcf7 .template03 input[type=checkbox] + span::after {
  content: "";
  opacity: 0;
  display: block;
  position: absolute;
  top: 0.15em;
  left: 0.44em;
  width: 0.4em;
  height: .8em;
  border-bottom: 3px solid #000;
  border-right: 3px solid #000;
  box-sizing: border-box;
  transform: rotate(40deg);
  transition: all .25s;
}
.wpcf7 .template03 input[type=checkbox]:checked + span {
  color: #333;
  transition: all .25s ease;
}
.wpcf7 .template03 input[type=checkbox]:checked + span::before {
  opacity: 0;
}
.wpcf7 .template03 input[type=checkbox]:checked + span::after {
  opacity: 1;
  border-bottom: 2px solid #333;
  border-right: 2px solid #333;
  transform: rotate(400deg);
  transition: all .25s;
}
/* ラジオボタン */
.wpcf7 .template03 input[type=radio] {
  display: none;
}
.wpcf7 .template03 input[type=radio] + span {
  cursor: pointer;
  font-size: 14px !important;
  position: relative;
  padding: 0 1em 0 1.8em;
  font-size: inherit;
  box-sizing: border-box;
  transition: opacity .25s ease;
}
.wpcf7 .template03 input[type=radio] + span::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 1em;
  height: 1em;
  border: 1px solid #ccc;
  border-radius: 50%;
  background-color: #fff;
  box-sizing: border-box;
  transition: opacity .25s ease; 
}
.wpcf7 .template03 input[type=radio] + span::after {
  content: "";
  display: block;
  opacity: 0;
  position: absolute;
  border: 1px solid #ccc;
  box-sizing: border-box;
  transition: opacity .25s ease;
}
.wpcf7 .template03 input[type=radio]:checked + span {
  color: #333;
  transition: all .25s ease;
}
.wpcf7 .template03 input[type=radio]:checked + span::before {
  background-color: transparent;
}
.wpcf7 .template03 input[type=radio]:checked + span::after {
  opacity: 1;
  top: 0;
  left: 0;
  width: 1em;
  height: 1em;
  border: 2px solid $b_color;
  border-radius: 50%;
  background-color: transparent;
  animation-name: border-circle;
  animation-duration: .25s;
  transition: opacity .25s ease;
}
/* セレクト */
.wpcf7 .template03 span.select-wrap {
  position: relative;
  width: 250px;
  margin: 0;
}
.wpcf7 .template03 select {
  cursor: pointer;
  width: 250px;
  max-width: 100%;
  margin: 0;
  padding: .5em 1.8em .5em 1em;
  border: 1px solid #333;
  border-left: 4px solid #333;
  border-radius: 0;
  box-shadow: none;
  background-color: #fff;
  color: inherit;
  font-size: inherit;
  line-height: 1;
  box-sizing: border-box;
  transition: border-color 0.2s ease, outline 0.2s ease;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.wpcf7 .template03 select::-ms-expand {
  display: none;
}
.wpcf7 .template03 span.select-wrap::after {
  content: "";
  pointer-events: none;
  position: absolute;
  display: block;
  width: .4em;
  height: .4em;
  top: calc(50% + .1em);
  right: .8em;
  margin-top: -3px;
  border-bottom: 1px solid #b3b3b3;
  border-right: 1px solid #b3b3b3;
  transform: rotate(45deg) translateY(-50%);
  transform-origin: 50% 0;
  box-sizing: border-box;
}
.wpcf7 .template03 select:focus {
  outline: 0;
  box-shadow: 2px 3px 2px 1px rgba(205,205,205,.7);
}
.wpcf7 .template03 span.select-wrap:focus-within::after {
  border-bottom: 1px solid #080808;
  border-right: 1px solid #080808;
}
/* 送信ボタン */
.wpcf7 .template03 .submit-btn {
  // overflow: hidden; 
  // position: relative;
  // width: 320px;
  // height: 65px;
  // max-width: 100%;
  // margin: 0;
  // padding: 0;
  // margin-left: auto;
  // margin-right: auto;
}
.wpcf7 .template03 input[type="submit"] {
  color: #fff;
  background: #306dd4;
  font-size: 16px;
  font-weight: 700;
  line-height: 1;
  padding: 15px 30px;
  text-decoration: none;
  position: relative;
  transition: all .2s ease-out;
  border: none;
  
  &:hover {
      opacity: .8;
  } 

  // cursor: pointer;
  // position: relative;
  // width: 320px;
  // height: 65px;
  // max-width: 100%;
  // padding: 18px;
  // border-radius: 0;
  // box-shadow: none;
  // border: 1px solid #000;
  // background-color: transparent;
  // color: #fff;
  // font-size: 18px;
  // text-align: center;
  // box-sizing: border-box;
  // transition: opacity .25s;
  // -webkit-appearance: none;
  // -moz-appearance: none;
  // appearance: none;
  // z-index: 10;
}
// .wpcf7 .template03 input[type="submit"] ~ div.submit-bg {
//   position: absolute;
//   top: 0;
//   left: 0;
//   margin: 0;
//   padding: 0;
//   width: 200%;
//   height: 65px;
//   background-color: #fff;
//   background-image: linear-gradient(90deg, #333, #333);
//   background-position: 100% 0;
//   background-size: 50% 100%;
//   background-repeat: no-repeat;
//   box-sizing: border-box;
//   transform: translateX(-50%);
//   transition: transform .25s;
//   z-index: 1;
// }
// .wpcf7 .template03 input[type="submit"]:hover {
//   color: #333;
// }
// .wpcf7 .template03 input[type="submit"]:hover ~ div.submit-bg {
//   transform: translateX(-4px);
//   transition: transform .25s;
// }
// .wpcf7 .template03 input[type="submit"]:focus {
//   outline: 0;
// }
// .wpcf7 .template03 input[type="submit"]:disabled {
//   cursor: not-allowed;
//   box-shadow: none!important;
//   border: 1px solid #f8f8f8!important;
//   background-color: #f8f8f8!important;
//   color: #999!important;
// }
.wpcf7 .template03 span.wpcf7-list-item {
  margin: 0 1em .2em 0;
}
.wpcf7 .template03 .ajax-loader {
  display: block;
}
@keyframes border-circle {
  0% {
    width: 0;
    height: 0;
    transform: translate(.5em, .5em);
  }
  100% {
    width: 1em;
    height: 1em;
    transform: translate(0, 0);
  }
}
@media only screen and (max-width: 768px) {
  .wpcf7 .template03 span.wpcf7-list-item {
    display: block;
  }
  .wpcf7 .template03 div.form__row {
    display: block;
  }
  .wpcf7 .template03 p.form__label {
    width: 100%;
    padding: 1.5em 1em 0;
  }
  .wpcf7 .template03 p.form__body {
    width: 100%;
    padding: 1em 1em 1.5em;
  }
}

// 入力エラー
.wpcf7-not-valid-tip {
    color: #dc3232;
    font-size: 12px;
    font-weight: 700;
    display: block;
    margin-top: 5px;
}