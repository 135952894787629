body, p, h1, h2, h3, h4, h5, h6 {
  font-family: $font;
  text-rendering: optimizeLegibility;
  background: transparent;
  -webkit-font-smoothing: antialiased;
  line-height: 1.6;
  letter-spacing: 0.06em;
  
  color: $color;
  font-family: $font;
}

h1, h2, .h1, .h2 {
 text-align: center;
}

// テーマ背景
div {
  background: transparent;
}

// フォント
p, a, small {
  font-family: $font;
  color: $color;
  font-weight: 500;
}

h1, h2, h3, h4, h5, h6, strong {
  font-weight: 700;
}

.h1, .h2 {
  font-weight: 500;
}

// 文字サイズ
p, li {
  font-size: 14px;
  // line-height: 26px;
}

h1 {
  font-size: 32px;
  // line-height: 40px;
}
.h1 {
  font-size: 14px;
  // line-height: 26px;
}

h2 {
  font-size: 24px;
  // line-height: 34px;
}
.h2 {
  font-size: 13px;
  // line-height: 18px;
}

h3 {
  font-size: 19px;
  // line-height: 35px;
}

h4 {
  font-size: 16px;
  // line-height: 26px;
  margin-top: 0;
  margin-bottom: 0;
}
@include md {
  p, li {
    font-size: 16px;
    // line-height: 32px;
  }

  h1 {
    font-size: 40px;
    // line-height: 72px;
  }
  
  h2 {
    font-size: 30px;
    // line-height: 42px;
  }
  
  h3 {
    font-size: 24px;
    // line-height: 42px;
  }
  
  h4 {
    font-size: 18px;
    // line-height: 32px;
  }
}

// 文字マージン
h3,p {
  margin-bottom: 20px;
}
.h2 {
  margin-bottom: 50px;  
}
@include md {
  .h2 {
    margin-bottom: 60px;  
  }
  h3,p {
    margin-bottom: 30px;
  }
}

// 文字オプション
.m-color {
  color:$b_color;
}
.bg-m-color {
  background: rgba($b_color, .2);
}
.red-color {
  color: #D43030;
}
.center {
  text-align: center; 
}
.left, .tl {
  text-align: left; 
}
.tr {
  text-align: right; 
}
.mb-0 {
  margin-bottom: 0;
}

// その他初期設定
img {
  width: 100%;
	height: auto;
}
ul:not([class]) {
  padding: 10px;
  margin-bottom: 20px;
  background: #F4F2E6;
  
  li {
    list-style-position: inside;
  }
}
@include md {
    ul:not([class]) {
      padding: 20px;
    }
}

// セクション
.sec-wrap {
  padding-top: 80px;
  padding-bottom: 80px;
}
@include md {
  .sec-wrap {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

.sec-inner {
  max-width: 980px;
  padding: 0 20px;
  margin: 0 auto;
}

.note {
  padding-bottom: 30px;
  
  &:last-of-type {
    padding-bottom: 0px;
  }
}
@include md {
  .note {
    padding-bottom: 40px;
    &:last-of-type {
      padding-bottom: 0px;
    }
  }
}

// ニュース
.news-list {
  list-style: none;
  
  li {
    p {
      text-align:left;
      margin-bottom: 5px;
    }
    a {
      transition: all .2s ease-out;
      font-weight: 700;
      
      &:hover {
        opacity: .6;
      }
    }
  }

  li{
    
    &:first-of-type {
      border-top: 1px solid #707070;
    }
    &:not(:last-of-type) {
      border-bottom: 1px dashed #707070;
    }
    &:last-of-type {
      border-bottom: 1px solid #707070;
    }
  }
  li{
    padding: 5px 10px;
  }
}

.tag-list + .tag-list {
  margin-left: 10px; 
}

.time {
  display: inline-block;

  font-size: 11px;
  line-height: 19px;
  font-weight: 700;
  color: #333;
}
.new {
  @extend .time;
  
  padding: 0 6px;
  color: white;
  background: #D43030;
}
.tag {
  @extend .new;
  
  background: #707070;
}
@include md {
  .time {

    font-size: 12px;
    line-height: 22px;
  }
}

.page-title {
  margin-top: 80px;
  height: 300px;
  position: relative;
  overflow: hidden;
  transition: all 1s 0s ease;
  
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover; 
  background-position: center center;
  
  h1,p {
    color: white;
  }
  p {
    margin-bottom: 0;
    margin-top: 1em;
  }
}

.page-title-wrap {
    @include absolute(0,0,0,0);
    z-index: 8;
    background: rgba($b_color,0.8);
    
    display: flex;
    flex-direction: column;
    justify-content: center;
}
@include md {
  .page-title {
    margin-top: 100px;
    height: 380px;
  }
}

.biz-title {
  margin-top: 0;
  height: 200px;
  
  position: relative;
  overflow: hidden;
  transition: all 1s 0s ease;
  
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover; 
  background-position: center center;
  
  h2,p {
    color: white;
  }
}
@include md {
  .biz-title {
    height: 250px;
  }
}
.biz-title-wrap {
    @include absolute(0,0,0,0);
    z-index: 8;
    background: rgba(#000,0.4);
    
    display: flex;
    flex-direction: column;
    justify-content: center;
}