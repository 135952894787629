.biz-item {
  height: 380px;
  position: relative;
  overflow: hidden;
  
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover; 
  background-position: center center;
  transition: all 3s 0s ease;
  
  h2,p {
    color: white;
  }
  p {
    margin-bottom: 0;
    margin-top: 1em;
  }
}

.biz-wrap {
    @include absolute(0,0,100px,0);
    z-index: 8;
    background: rgba($b_color,0.8);
    
    padding-left: 20px;
    padding-right: 20px;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: all 1s 0s ease;
}
@include md {
  .biz-item {
    &:nth-of-type(odd) .biz-wrap {
      right: 50%;
      bottom: 0;
    }  
    &:nth-of-type(even) .biz-wrap {
      left: 50%;
      bottom: 0;
    }  
  }
}