.thumbnail img {
    width: 100%;
    height: auto;
}

.single{
    img {
        width: 100%;
        height: auto;
    }

    .thumbnail {
      margin-bottom: 40px
    }
    
    // コモン
    pre {
        font-size: 15px;
        background-color: #fafafa;
        padding: 20px 15px;
        color: #5a5a5a;
        font-weight: 500;
        line-height: 1.5;
        outline: 1px solid #f0f0f0;
        border-color: #f0f0f0;
        
        &[class*="language-"] {
            margin-left: 40px;
            margin-right: 40px;
            margin-bottom: 40px
        }
    }
    @include md {
        pre {
            font-size: 17px;
            background-color: #fafafa;
            padding: 40px 25px;
        }
    }
    
    div.pre {
        font-size: 16px;
        padding: 20px;
        background-color: #fafafa;
        outline: 1px solid #f0f0f0;
        border-color: #f0f0f0;
        text-align: left;
        margin: 10px;
        margin-bottom: 60px;
        margin-top: 15px
    }
    @include md {
        div.pre {
            margin: 40px;
        }
    }
    
    blockquote {
        background: #eee;
        font-style: italic;
        padding-top: 35px;
        padding-bottom: 35px;
        color: #676767;
        position: relative
        
        p {
            margin-top: 10px
        }
        
        &:before {
            position: absolute;
            top: 13px;
            content: "\f10d";
            font-family: FontAwesome;
            color: #b0b0b0;
            font-size: 28px;
            line-height: 1
        }
    }
    
    // テーブル
    
    .table-responsive {
        padding: 0;
        margin-bottom: 20px;
        text-align: left;
        font-size: 14px
    }
    @include md {
        .table-responsive {
            padding: 0 5%
        }
    }
    
    td, th {
      border: 1px solid #cfcfcf
    }
    
    .table{
        >thead>tr>th, >thead>tr>td, >tbody>tr>th, >tbody>tr>td, >tfoot>tr>th, >tfoot>tr>td {
            padding: 15px;
            border-top: 1px solid #ddd
        }
    }

}

