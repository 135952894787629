aside {
    padding-left: 20px
    
    div {
        background: #fff;
        margin-top: 35px
    }
    
    h4 {
        margin-top: 50px;
        text-align: center
    }
}

