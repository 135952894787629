// アーカイブタイトル
.news-title {
    background: $b_color;
    padding: 15px;
    margin-bottom: 30px;
    
    h2 {
        color: white;
        font-size: 19px;
    }
}
@include md {
        .news-title {
        background: $b_color;
        padding: 20px;
        margin-bottom: 40px;
        h2 {
            font-size: 24px;
        }
    }
}

// 記事詳細
span.tag a {
   color:#fff; 
   text-decoration: none;
}
.article {
    background: #fff;
    margin-bottom: 15px;
}
@include md {
    .article {
        margin-bottom: 20px;
    }
}

.article-wrap {
    padding: 20px;
    
    .btn {
        margin-top: 0;
    }
}
@include md {
    .article-wrap {
        padding: 30px;
    }
}

// サイドバー
.side-item {
    background: #fff;
    margin-bottom: 20px;
    a {
      transition: all .2s ease-out;
      
      &:hover {
        opacity: .6;
      }
    }
}
@include md {
    .side-item {
        margin-bottom: 30px;
    }
}

.side-title {
    background: #89949D;
    padding: 10px;
    h3 {
        font-size: 16px;
        margin-bottom: 0;
        color: white;
    }
}
@include md {
        .side-title {
            h3 {
                font-size: 14px;
            }
    }
}

.site-wrap {
    border: 1px solid #89949D;
    ul { 
        list-style: none;
        margin-bottom: 0;
        background: white;
    }
}