.author {
    padding-bottom: 40px
    
    img {
        width: 100px;
        margin: 40px auto 0
        
    }
    @include md {
        img {
            width: 130px;
        }
    }
    
    h4 {
        text-align: center;
        font-weight: 600;
        margin-top: 35px
    }
    
    p {
        color: #7B7B7B;
        font-size: 12px;
        line-height: 25px;
        padding-left: 20px;
        padding-right: 20px;
        margin-top: 40px
    }
}