// footer {
//     overflow: hidden;
// }

// ページトップへ
.top-scroll {

    @include fixed('',20px,35px,'');
    border-radius: 50px;
    text-decoration: none;
    width: 50px;
    height: 50px;
    background: rgba(0,0,0,0.8);
    color: white;
    
    z-index: 10;
    
    padding: 10px 0;
    
    transition: all 0.4s ease-out;
    &:hover {
        opacity: .6;
    }
    
    p {
        text-align: center;
        color: white;
        font-size: 11px;
        font-weight: 700;
        line-height: 1;
        margin-bottom: 0;
        
        &:first-of-type{
            @include absolute(10px,0,'',0);
        }
        &:last-of-type{
            @include absolute('',0,13px,0);
        }
    }
}
@include md {
    .top-scroll {
        right: 30px;
        bottom: 40px;

        width: 60px;
        height: 60px;

        padding: 10px 0;
        
        p {
            font-size: 13px;
            
            &:first-of-type{
                @include absolute(12px,0,'',0);
            }
            &:last-of-type{
                @include absolute('',0,15px,0);
            }
        }
    }
}
/*
* 問い合わせボタン
*/
.contact-link {
    display: block;
    height: 250px;
    
    position: relative;
    overflow: hidden;
    text-decoration: none;


    
    .h2 {
        margin-bottom: 0;
    }
    
 
}
@include md {
    .contact-link {
        height: 280px;
    }
}

.contact-link .contact-img {
    @include absolute(0,0,0,0);
    z-index: 7;
    
    background-repeat: no-repeat;
    background-size: cover; 
    background-position: center center;
    transition: all 0.8s ease-out;
	transform: matrix3d(
		1, 0, 0, 0,
		0, 1, 0, 0,
		0, 0, 1, 0,
		0, 0, 0, 1
	);
    transition: all 3s 0s ease;
    
    &::before {
        content: '';
        display: block;
        z-index: 5;
        @include absolute(0,0,0,0);
        transition: all .8s 0s ease;
        background-color: rgba(#000,.0);
    }
}

.contact-wrap {
    @include absolute(0,0,0,0);
    z-index: 8;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: all 1s 0s ease;
    
    &::before {
        content: '';
        transition: all 0.4s ease-out;
        display: block;
        z-index: -1;
        @include absolute(0,0,0,0);
        background-color: rgba(#fff,.8);
    }
}

.contact-link:hover {
    color: white;

    .contact-img {
    	transform: matrix3d(
    		1, 0, 0, 0,
    		0, 1, 0, 0,
    		0, 0, 1, 0,
    		0, 0, 0, 0.9
    	);
    	&::before{
    	    background-color: rgba(#000,.8);
    	}
    }
    
    .contact-wrap::before {
        opacity: 0;
        @include absolute(10%,10%,10%,10%);
    }
    
    h2, p {
        color: white;
    }
}

/*
* フッダーメッセージ
*/
.footer_message {
    position: relative;
    padding-bottom: 20px;
    background: $b_color;
    height: 250px;
    
    p, a {
      color: white;
      text-align: center;
    }
}
@include md {
    .footer_message {
        height: 280px;
    }    
}

.message-wrap {
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
    @include center(xy);
    top: 45%;
}
@include md {
    .message-wrap {
        margin-left: auto;
        margin-right: auto;
        max-width: 1140px;
    }    
}


.footer_message .message {
    margin-bottom: 20px;
    font-size: 26px;
    line-height: 1.5;
    font-weight: 700;
    span {
        font-size: 30px;
    }
}
@include md {
    .footer_message .message {
        margin-bottom: 30px;
        font-size: 32px;
        span {
            font-size: 36px;
        }
    }    
}

.contact-info {
    margin-top: 20px;
    .msg {
        font-size: 12px;
        margin-bottom: 0;
        line-height: 1.2;
    }
    .tel {
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 0;
        line-height: 1.2;
    }
    .day {
        font-size: 11px;
        margin-bottom: 0;
        font-weight: 500;
        line-height: 1.2;
    }
}
@include md {
.contact-info {
    .msg {
        font-size: 14px;
    }
    .tel {
        font-size: 28px;
    }
    .day {
        font-size: 12px;
    }
} 
}

.right {
    @include absolute('',0,20px,0)
    font-size: 10px;
    line-height: 1;
    color: white;
    font-weight: 400;
    margin-bottom: 0;
}
