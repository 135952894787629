.catch{
    height: 100vh;
    width: 100%;
    position: relative; 
}

.catch-wrap {
    height: 100%;
    // overflow: hidden;
    @include absolute(0,0,0,0);
    background: rgba(0,0,0,0.6);
    z-index: 5;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.catch-wrap p {
    display: none;
    color: #fff;
    font-size: 17px;
    line-height: 1.8;
    padding-left: 10px;
    padding-right: 10px;
    
    max-width: 380px;
    margin-left: auto;
    margin-right: auto;
}
.catch-wrap span {
    font-size: 22px;
    font-weight: 800;
    text-decoration: underline;
    line-height: 1;
    
    &::before, &::after {
      content: "\A";
      white-space: pre;
    }
}
@include sm {
  .catch-wrap p {
      font-size: 24px;
      max-width: 1140px;
  }
  .catch-wrap span {
      font-size: 32px;
      
      &::before {
        content: "";
        white-space: none;
      }
  }
}
@include lg {
  .catch-wrap p {
      font-size: 36px;
      max-width: 1140px;
  }
  .catch-wrap span {
      font-size: 48px;
      
      &::before {
        content: "";
        white-space: none;
      }
  }
}

// スクロールダウン
.scroll-down {
    display: block;
    position: absolute;
    text-align: center;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 6;
    padding: 10px 10px 123px;
    color: #fff;
    font-size: 12px;
    line-height: 1;
    letter-spacing: .2em;
    text-transform: uppercase;
    text-decoration: none;
    transition: .2s;
    overflow: hidden;
}
.scroll-down::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 1px;
    height: 100px;
    background: rgba(255, 255, 255, 0.4);
}
.scroll-down::after {
    display: block;
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 1px;
    height: 100px;
    background: #fff;
    animation: sdl 2.5s cubic-bezier(1, 0, 0, 1) infinite;
    // background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.8) 80%, rgba(0, 0, 0, 0.8) 100%);
}
@include md {
    .scroll-down {
        font-size: 14px;
    }
}

@keyframes sdl {
  0% {
    transform: scale(1, 0);
    transform-origin: 0 0;
  }
  50% {
    transform: scale(1, 1);
    transform-origin: 0 0;
  }
  50.1% {
    transform: scale(1, 1);
    transform-origin: 0 100%;
  }
  100% {
    transform: scale(1, 0);
    transform-origin: 0 100%;
  }
}

// スライドショー
@keyframes zoomUp {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.15);
  }
}

.swiper-slide-active .slide-img,
.swiper-slide-duplicate-active .slide-img,
.swiper-slide-prev .slide-img{
  animation: zoomUp 10s linear 0s 1 normal both;  
}
.slide-img img {
    display: block;
    width: 100%;
    height: 100vh;
    object-fit: cover;
}