.pager {
    display: flex;
    justify-content: center;
    
    > p {
        margin: 0;
    }
}

.pager span, .pager a {
	font-size: 15px;
	padding: 10px 15px;
	border: 1px solid $b_color;
	margin: 0 5px;
	color: $b_color;
}
.pager span.current {
	border: 1px solid $b_color;
	background: $b_color;
	color: #fff;
}
.pager a:hover {
	color: #333;
	opacity: .8;
}