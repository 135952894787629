.loading {
    overflow: hidden;
    z-index: 999;
    @include fixed(0,0,0,0);
    background: white;
    
    height: 100vh;
    width: 100vw;
}
.loading.behind {
    z-index: -1;
}

.loading-wrap {

    width: 250px;
    height: 250px;
    
    @include center(xy);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
}

.js-load {
    @include fixed(0,0,0,0);
    height: 100vh;
    width: 100vw;
    z-index: 1000;
}
.behind.js-load {
    z-index: -20;
}
.js-load .motion-scroll {
    @include absolute(0,0,0,0);
}

.js-load .motion-scroll, .motion-inner {
    
    height: 100%;
    width: 100%;
}


