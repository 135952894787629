.btn {
    margin-top: 50px;
    text-align: center;
    transition: all .6s ease-out;
    a {
        display: inline-block;
        color: white;
        background: $b_color;
        
        font-size: 14px;
        line-height: 1;
        padding: 10px 25px;
        
        text-decoration: none;  
        
        position: relative;
        transition: all .2s ease-out;
        
        &:hover {
            opacity: .8;
        } 
        &::after {
            content: '';
            display: block;
            position: absolute;
            @include center(xy);
            z-index: -1;
            width: 0;
            height: 0;
            border-radius: 50%;
            border: 2px solid $b_color;
            opacity: .8;
            transition: all .2s ease-out;
            pointer-events: none;
        }
        &:hover::after {
            width: 150%;
            padding-top: 150%;
        }
    }
    
}


@include md {
    .btn {
        margin-top: 60px;
        a {
            padding: 15px 35px;
        }
    }
}

.btn-n {
    @extend .btn;
    a {
        &::after {
            display: none;
        }
    }
}

.btn-w {
    @extend .btn;
    a {
        color: $b_color;
        background: white;
        
        &::after {
            border-color: white;
        }
    }
}

.btn-pp {
    @extend .btn-w;
    margin-top: 20px;
    a {
        font-size: 12px;
        padding: 10px 15px;
        
        &::after {
            display: none;
        }
    }
}
@include md {

    .btn-pp {
        a {
            font-size: 12px;
            padding: 10px 20px;
        }
    }   
}

// SNSボタン
.sns-btn {
    display: flex;
    justify-content: center;
    list-style: none;
    
    li {
        height: 35px;
        width: 35px; 
        
        transition: all 0.4s ease-out;
        &:hover {
            opacity: .6;
        }
    }
    li:not(:last-of-type) {
        margin-right: 20px;
    }
    a {
        font-size: 25px;
        color: $b_color;
        text-decoration: none;
        
        height: 35px;
        width: 35px;
        border-radius: 50%;
        background: white;
        display: block;
        position: relative;
        
        span {
            display:block;
            @include center(xy);
        }
    }
}
@include md {
    .sns-btn {  
        li {
            height: 40px;
            width: 40px; 
        }
        li:not(:last-of-type) {
            margin-right: 25px;
        }
        a {
            height: 40px;
            width: 40px;
        }
    } 
}
