.thumbnail {
  border: none;
  padding: 0
}

.thumbnail a {
  position: relative;
  display: table;
  width: 100%;
  height: 100px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat
}

hr {
  width: 20%;
  border: 1px solid #374CA5
}

.nmb {
  margin-bottom: 0 !important
}

.pc-none {
    display: none !important
}
@include md {
　.pc-none {
    display: block !important
  }
  .sp-none {
    display: none !important
  }
}
