.motion-scroll {
  display: inline-block;
  position: relative;
  overflow: hidden;
}
.motion-scroll:after {
  content: '';
  position: absolute;
  opacity: 1;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  background-color: $b_color;
  transform: translate3d(-101%, 0, 0);
}
.js-scroll, .js-load {
    &.show .motion-scroll:after {
      transition-property: transform, opacity;
      transition-duration: 0.5s;
      transition-delay: 0s;
      transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
      transform: translate3d(0, 0, 0);
    }
}
.js-scroll, .js-load {
    &.done .motion-scroll:after {
      transition-property: transform;
      transition-duration: 0.5s;
      transition-delay: 0s;
      transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
      transform: translate3d(103%, 0, 0);
    }
}

.motion-scroll .motion-inner {
//   display: inline-block;
  opacity: 0;
}
.js-scroll, .js-load {
    &.done .motion-scroll .motion-inner {
      opacity: 1;
    }
}

.js-scroll .motion-scroll {
    width: 100%;
}