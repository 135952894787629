/*
* メディアクエリ
*
* @include サイズ {}
*/
@mixin sm {
  @media screen and (min-width: $sm) {
    @content;
  }
}
@mixin md {
  @media screen and (min-width: $md) {
    @content;
  }
}
@mixin lg {
  @media screen and (min-width: $lg) {
    @content;
  }
}
@mixin xl {
  @media screen and (min-width: $xl) {
    @content;
  }
}

/*
* absolute : position:absolute; および top / right / bottom / left の設定を一括設定
*/
@mixin absolute($top:null, $right:null, $bottom:null, $left:null){
  position: absolute;
  @if $top != null{
    top: $top;
  }
  @if $right != null{
    right: $right;
  }
  @if $bottom != null{
    bottom: $bottom;
  }
  @if $left != null{
    left: $left;
  }
}

@mixin fixed($top:null, $right:null, $bottom:null, $left:null){
  position: fixed;
  @if $top != null{
    top: $top;
  }
  @if $right != null{
    right: $right;
  }
  @if $bottom != null{
    bottom: $bottom;
  }
  @if $left != null{
    left: $left;
  }
}

@mixin center($direction) {
	position: absolute;
	@if $direction==xy {
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	@else if $direction==x {
		left: 50%;
		transform: translateX(-50%);
	}
	@else if $direction==y {
		top: 50%;
		transform: translateY(-50%);
	}
}