.open {
    overflow: hidden;
}


header, #logo img {
        transition: all .3s ease-out;
}

header {
    position: fixed !important;
    top: 0;
    left: 0;
    right: 0;
    height: 80px;
    background: white;
    z-index: 10;
}
header.hide {
    top: -100px;
}
.min-head header {
    height: 60px;
}
@include md {
    header {
        height: 100px;
    }
    .min-head header {
        height: 70px;
    }
}

.head-wrap {
    display: flex;
    justify-content: space-between;
    height: 100%;
}

#logo {
    z-index: 30;
    padding: 10px;
    height: 100%;
    transition: 0.2s ease-in-out;
    
    a {
        display: block;
        height: 100%;
    }
    img {
        max-height: 100%;
        width: auto;
        vertical-align:baseline;
    }
    
    &:hover {
        opacity: .4;
    }
}

.header-space {
    margin-top: 80px;
    width: 100%;
}

/*
* nav-sp
*
* グローバルナビ(SP)
*/

// メニューボタン
#nav-toggle {
    margin: 20px 20px;  
}
:not(.open).min-head #nav-toggle {
    margin: 15px 20px; 
}

#nav-toggle {
    cursor: pointer;
    z-index: 150;
    width: 40px;
    
    div {
        width: 30px;
        height: 25px;
        margin-left: auto;
        margin-right: auto;
    }
    
    span{
        transition: all 300ms 0s ease;
        display: block;
        background: #707070;
        height: 1px;
        
        margin-bottom: 10px;
        
        &:last-of-type {
            margin-bottom: 0;
        }
    }
    
    p:before {
        content:"MENU";
        display: block;
        font-weight: 300;
        font-size: 6px;
        line-height: 18px;
        text-align: center;
        font-family: $font;
        color: $color;
        transition: all .3s ease-out;
        text-transform: uppercase;
    }
}
.open #nav-toggle {
    span{
        transition: all 300ms 0s ease;
        display: block;
        background: white;
        height: 1px;
        
        margin-bottom: 10px;
        
        &:first-of-type {
            transform: translateY(12px) rotate(-135deg);
            zoom: 1;
        }
        &:nth-of-type(2) {
            width: 0;
        }
        &:last-of-type {
            transform: translateY(-11px) rotate(135deg);
            zoom: 1;
        }
    }
    
    p:before {
        content:"CLOSE";
        color: white;
    }
}

// メニュー
#global-nav {
    visibility: hidden;
    position: fixed;
    vertical-align: middle;
    color: #fff;
    top: 0;
    height: 100%;
    width: 100%;
    text-align: center;
    z-index: 120;
    
    ul {
        vertical-align: middle;
        list-style: none;
        margin-top: 80px;
        margin-left: 50px;
        margin-right: 50px;
    }
    a {
        padding-top: 20px;
        padding-bottom: 20px;
        color: #fff;
        text-decoration: none;
        display: block;
    }
    
    strong {
        display: block;
        font-size: 18px;
        margin-bottom: 5px;
        line-height: 1;
        color: white;
        text-transform: uppercase;
    }
    span {
        display: block;
        font-size: 12px;
        font-weight: 300;
        line-height: 1;
        color: white;
    }
    
    // 現ページ
    .current_page_item a, .current_page_item a:hover{
        background: #fff;
        
        strong,span {
            color: $b_color;
        }
    }
}
.open #global-nav {
  visibility: visible;
}

// メニューリスト
#global-nav li {
    padding: auto 20px;}
#global-nav li a {
    position: relative;
    overflow: hidden;
    color: transparent;
    z-index: 10;
}
#global-nav ul li {
  opacity: 0;
  -webkit-transform: scaleX(0) translateX(-260px);
  transform: scaleX(0) translateX(-260px);
  -webkit-transition: none;
  transition: none;
}
.open #global-nav ul li {
  opacity: 1;
  -webkit-transform: scaleX(1) translateX(0);
  transform: scaleX(1) translateX(0);

  -webkit-transition: all .3s ease-out;
  transition: all .3s ease-out;

  -webkit-transition-delay: .1s;
  transition-delay: .1s;
}
.open #global-nav ul li {
    &:nth-child(2) {
        -webkit-transition-delay: .2s;
        transition-delay: .2s;
    }
    &:nth-child(3) {
        -webkit-transition-delay: .3s;
        transition-delay: .3s;
    }
    &:nth-child(4) {
        -webkit-transition-delay: .4s;
        transition-delay: .4s;
    }
    &:nth-child(5) {
        -webkit-transition-delay: .5s;
        transition-delay: .5s;
    }
    &:nth-child(6) {
        -webkit-transition-delay: .6s;
        transition-delay: .6s;
    }    
}

// メニュー背景
#nav-bg {
  content: "";
  z-index: 100;
  width: 2400px;
  height: 2400px;
  margin-right: -1200px;
  margin-top: -1200px;
  top: -50px;
  right: -50px;
  display: block;
  position: fixed;
  background: $b_color;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  -webkit-transition: all .3s ease-out;
  transition: all .3s ease-out;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: scale(.02);
  transform: scale(.02);

}
.open #nav-bg {
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all .6s ease-out;
  transition: all .6s ease-out;
}


/*
* nav
*
* グローバルナビ(PC)
*/
#nav {
    overflow: auto;
    height: auto;
    
    ul {
        display: flex;
    }
    
    ul, li {
        height: 100%;
    }
    
    a {
        display: block;
        height: 100%;
        padding: 2em 20px 0;
        text-align: center;
        text-decoration: none;
    }
}
.min-head #nav {
    a {
        padding-top: 1em;
    }
}

#nav a{
    strong {
        display: block;
        font-size: 15px;
        margin-bottom: 5px;
        line-height: 1;
        color: $color;
        text-transform: uppercase;
    }
    span {
        display: block;
        font-size: 12px;
        line-height: 1;
        font-weight: 300;
        color: $color;
    }
} 
#nav li:nth-last-of-type(2) a {
    padding-right: 40px;
}
#nav li:last-of-type a {
    padding-left: 30px;
    padding-right: 30px;
    background: $b_color;
    
    strong, span {
        color: white;
    }
}

// カレントページ
#nav .current_page_item a{
    background: #F0F3F5;
    box-sizing: border-box;
    border-bottom: 5px solid $b_color;
}
#nav li:last-of-type.current_page_item a{
    background: #F0F3F5;
    box-sizing: border-box;
    border-bottom: 5px solid $a_color;
    
    span {color: $gray;}
    strong {color: $color;}
}


/*
* nav
*
* アニメーション
*/
#nav a {
        transition: all .3s ease-out;
    &:before, &:after, strong ,span {
        transition: all .3s ease-out;
    }
}
#nav li:not(.current_page_item) a {
    position: relative;
    overflow: hidden;
    color: transparent;
    z-index: 10;
    
    &:before, &:after {
        content: '';
        @include absolute(0,0,0,0);
        z-index: -1;
    }

    &:before {
        right: -50px;
        transform: translateX(-100%);
        border-right: 50px solid transparent;
        border-bottom: 100px solid $b_color;
    }
    &:after {
        left: -50px;
        transform: translateX(100%);
        border-left: 50px solid transparent;
        border-top: 100px solid $b_color;
    }

    &:hover {
        strong {color: white;}
        span {color: white;}
        &:before {transform: translateX(-49%);}
        &:after {transform: translateX(49%);}
    }
}
#nav li:last-of-type:not(.current_page_item) a {
    position: relative;
    overflow: hidden;
    color: transparent;
    z-index: 10;
    
    &:before, &:after {
        content: '';
        @include absolute(0,0,0,0);
        z-index: -1;
    }

    &:before {
        right: -50px;
        transform: translateX(-100%);
        border-right: 50px solid transparent;
        border-bottom: 100px solid $a_color;
    }
    &:after {
        left: -50px;
        transform: translateX(100%);
        border-left: 50px solid transparent;
        border-top: 100px solid $a_color;
    }

    &:hover {
        strong {color: white;}
        span {color: white;}
        &:before {transform: translateX(-49%);}
        &:after {transform: translateX(49%);}
    }
}