/*
* BUSINESS
*/

// 事業スクロールナビ
.scroll-nav {
  max-width: 600px;
  padding: 50px 20px;
  margin: 0 auto;
  display: flex;
}@include md {
    .scroll-nav {
        padding: 60px 20px;
    }
}
.scroll-item {
  height: 85px;
  width: 50%;
  border: solid 1px #ccc;
  border-right: none;
  
  &:last-of-type {
    border-right: solid 1px #ccc;
  }
  
  .contact-link {
    height: 100%;
    width: 100%;
  }
  p {
    margin-bottom: 0 !important;
  }
}
@include md {
    .scroll-item {
        height: 100px;
    }
}

// セクション
section.busi {
    .page-title {
        height: 190px;
    
        h2 {
            color: white;
            text-align: center;
        }
    }
}
@include md {
    section.busi .page-title {
        height: 250px;
    }
}

// セミナー
.seminar {
  display: block;
}
.seminar-item {
  width: 100%;
  
  img {
    width: 100%;
    height:auto;
  }
}
@include sm {
  .seminar {
    display: flex;
  }
  .seminar-item {
    width: 50%;
    
    &:first-of-type {
      flex: 2;
      margin-right: 50px;
    }
    &:last-of-type {
      flex: 1;
    }
  }
}

// 実績
.work {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  transition: all .2s ease;
}
.work-item {
  flex-basis: auto;
  margin: 10px;
  transition: all .2s ease;
  
  img {
    width: 100%;
    height:auto;
  }
}

.work-wrap {
  background-repeat: no-repeat;
  background-size: cover; 
  background-position: center center;
  // background-color: linear-gradient(#05FBFF, #1E00FF);
  background-color: rgba($b_color,.6);
  background-blend-mode: multiply;
  height: 250px;
  width: 100%;
}
.work-txt {
  padding: 20px;
  p, h3 {
    color: white;
  }
}
@include sm {
  .work {
    display: flex;
  }
  .work-item {
    flex-basis: calc(50% - 20px);
  }
}

// フロー関連
@include md {
  .frow-box {
    display: flex;
  }
  .frow-item {
    width: 49%;
    
    &:first-of-type {
      order: 2;
    }
    &:last-of-type {
      order: 1;
    }
  }
}

// サークル
.circle-wrap5 {
    width: 70%;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 10%;
    padding-bottom: 13%;
}
@include md {
  .circle-wrap5 {
    padding-top: 10%;
    padding-bottom: 10%;
  }
}

.circle-wrap3 {
    width: 70%;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 4%;
    padding-top: 2%;
}
@include md {
  .circle-wrap3 {
    padding-bottom: 5%;
  }
}
    
.circle { 
  width: 100%;
  padding-top: 100%;

  border-radius: 50%;
  position: relative;
}

.circle-item {
  width: 50%; 
  height: 50%;
  position: absolute;
  left: 25%;
  top: 25%;
  border-radius: 50%;
  background: rgba($b_color,0.2);
}
.circle-title {
    color: $b_color;
    @include center(xy);
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 0 !important;
    font-size: 12px;
    line-height: 1.5em;
    font-weight: 700;
    text-align: center;
}
@include sm {
    .circle-title {
        font-size: 14px;
    }
}
@include md {
    .circle-title {
        font-size: 16px;
    }
}

.c5-1 { 
  animation: rotate_top 60s linear infinite;
}
@keyframes rotate_top { 
  0% { 
   transform: rotate(0deg) translateY(-80%) rotate(0deg);
  }
  100% {  
    transform: rotate(360deg) translateY(-80%) rotate(-360deg);
  }
}

.c5-2 { 
  animation: c5-2 60s linear infinite;
}
@keyframes c5-2 { 
  0% { 
  transform: rotate(0deg) translateX(76.085%) translateY(-24.721%) rotate(0deg);
  }
  100% {  
    transform: rotate(360deg) translateX(76.085%) translateY(-24.721%) rotate(-360deg);
  }
}

.c5-5 { 
  animation: c5-5 60s linear infinite;
}
@keyframes c5-5 { 
  0% { 
  transform: rotate(0deg) translateX(-76.085%) translateY(-24.721%) rotate(0deg);
  }
  100% {  
    transform: rotate(360deg) translateX(-76.085%) translateY(-24.721%) rotate(-360deg);
  }
}

.c5-4 { 
  animation: c5-4 60s linear infinite;
}
@keyframes c5-4 { 
  0% { 
  transform: rotate(0deg) translateX(-47.023%) translateY(64.721%) rotate(0deg);
  }
  100% {  
    transform: rotate(360deg) translateX(-47.023%) translateY(64.721%) rotate(-360deg);
  }
}

.c5-3 { 
  animation: c5-3 60s linear infinite;
}
@keyframes c5-3 { 
  0% { 
  transform: rotate(0deg) translateX(47.023%) translateY(64.721%) rotate(0deg);
  }
  100% {  
    transform: rotate(360deg) translateX(47.023%) translateY(64.721%) rotate(-360deg);
  }
}

.c3-1 { 
  animation: c3-1 60s linear infinite;
}
@keyframes c3-1 { 
  0% { 
   transform: rotate(0deg) translateY(-55%) rotate(0deg);
  }
  100% {  
    transform: rotate(360deg) translateY(-55%) rotate(-360deg);
  }
}

.c3-2 { 
  animation: c3-2 60s linear infinite;
}
@keyframes c3-2 { 
  0% { 
  transform: rotate(0deg) translateX(47.631%) translateY(27.5%) rotate(0deg);
  }
  100% {  
    transform: rotate(360deg) translateX(47.631%) translateY(27.5%) rotate(-360deg);
  }
}

.c3-3 { 
  animation: c3-3 60s linear infinite;
}
@keyframes c3-3 { 
  0% { 
  transform: rotate(0deg) translateX(-47.631%) translateY(27.5%) rotate(0deg);
  }
  100% {  
    transform: rotate(360deg) translateX(-47.631%) translateY(27.5%) rotate(-360deg);
  }
}

.q-item {
    box-shadow: 0 1px 1px rgba(0,0,0,0.05);
    border: 1px solid #e5e5e5;
    max-width: 500px;
    margin: auto;
}
.q-item p {
    font-size: 12px;
}
@include md {
  .q-item p {
    font-size: 14px;
  }
}
.q-head {
    display: flex;
    justify-content: space-between;
    
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
}
.q-right {
    i {
        margin-top: 5px;
        display: block;
        transform: rotate(180deg);
        text-align: right;
        transition: all .2s 0s ease;

        &.open{
                display: block;
                transform: rotate(0deg);
                text-align: right;
        }
    }
}
.q-txt {
    display: none;
    padding: 0 20px;
    p {
        text-align: left;
    }
}

.q-triangle {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 10px 0px 10px;
  border-color: $b_color transparent transparent transparent;
  
  margin: auto;
}
@include md {
  .q-triangle {
    border-width: 15px 15px 0px 15px;
    margin: auto;
  }
}